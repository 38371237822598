.react-datepicker-wrapper {
  /* padding: 11px !important; */
  border: 1px solid #bbb !important;
  border-radius: 4px;
  width: 90%;
  max-width: 400px;
  font-size: 14px;
  line-height: 1;
  background-image: url("./images/icons/pulldown_arrow.svg");
  background-repeat: no-repeat;
  background-position: right 12px top 15px;
}

#calender-id {
  width: 100%;
  padding: 11px;
}