.paginateContainer {
  width: fit-content;
  margin: 30px auto 0;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
ul.pagination {
  display: flex;
  column-gap: 12px;
}
li.page-item {
  width: 20px;
  height: 20px;
  background-color: #69a03a;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.1s ease;
}
a.page-link {
  padding-top: 1px;
  display: block;
}
a.page-link.active {
  cursor: pointer;
  background-color: #3b4b2e;
  color: #fff;
  text-align: center;
  font-size: 13px;
  padding-top: 3px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  transition: all 0.1s ease;
  font-weight: 700;
}

a.previous-link {
  cursor: pointer;
}
a.next-link {
  cursor: pointer;
}
a[aria-disabled="true"] {
  display: none;
}
